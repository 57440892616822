import checkEllipse from 'website/assets/check_ellipse_p1.svg'
import noImpactImage from 'website/assets/no_impact_icon_p1.svg'
import personalDataImage from 'website/assets/personal_data_p1.svg'
import personalizationImage from 'website/assets/personalization_image_p1.svg'

const assets: Record<string, string> = {
  checkEllipse,
  noImpactImage,
  personalDataImage,
  personalizationImage
}

export default assets
