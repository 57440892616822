import successFinancingFormImage from 'website/assets/success_financing_form_p1.svg'
import financingAlarm from 'website/assets/financing_alarm_p1.svg'
import financingCash from 'website/assets/financing_cash_p1.svg'

const assets: Record<string, string> = {
  successFinancingFormImage,
  financingAlarm,
  financingCash
}

export default assets
