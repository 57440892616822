import { StaticDocumentsApiProvider } from 'api/static_documents.api'

import poweredByCF from 'website/assets/poweredByCF.svg'
import fbIcon from 'website/assets/fb.svg'
import googleIcon from 'website/assets/google.svg'

const termsAndConditionsLink = StaticDocumentsApiProvider.getTermsAndConditionsUrl()
const privacyPolicyLink = StaticDocumentsApiProvider.getPrivacyPolicyUrl()

const assets: Record<string, string> = {
  fbIcon,
  googleIcon,
  poweredByCF,

  termsAndConditionsLink,
  privacyPolicyLink
}

export default assets
