import { HeaderDealFlowConfig } from 'website/components/types'

export default {
  Header: {
    default: {
      root: `
          color: var(--headerColor);
          background-color: var(--headerBackgroundColor);
          border-bottom: 2px solid var(--headerBottomBorderColor);
          display: grid;
          position: sticky;
          top: 0px;
          z-index: 1100;
  
          .DealerInfoFragment {
            justify-self: flex-end;
            max-width: 80vw;
  
            a {
              font-size: 12px;
              display: flex;
              align-items: center;
              color: #fff;
  
              svg {
                transform: scale(0.8);
              }
            }
          }
  
          > div > .ExtendedImage {
            height: auto;
            max-height: 40px;
            display: flex;
            align-items: center;
            
            img {
              max-height: 40px;
            }
            
            a {
              display: flex;
            }
            
            @media screen and (min-width: 1270px) {
              height: auto;
              max-height: 40px;
            }
          }
  
          min-height: var(--headerMobileHeight);
          
          .drawer-search-bar {
            .MuiButton-root {
              display: none;
            }
            
            .cf-input-container {
              :hover {
                border: 1px solid var(--mainColor);
              }
              
              &.focused {
                border: 1px solid var(--mainColor);

                svg {
                  circle {
                    stroke: #000;
                  }
  
                  path {
                    stroke: #000;
                  }
                }

                .close-btn {
                  path {
                    stroke: none;
                  }
                }
              }
            }
            
            .close-btn {
              margin-top: 3px;
              width: 12px;
              cursor: pointer;
            }

            @media screen and (min-width: 768px) {
              display: none;
            }
          }
          
          .SearchBar {
            .MuiButton-root {
              display: none;
            }
            
            .cf-input-container {
              background-color: rgba(250, 250, 250, 0.10);
              height: 40px!important;
              
              .cf-input-adornment, .cf-input {
                padding-top: 8px!important;
                padding-bottom: 8px!important;
              }
              
              .cf-start-adornment {
                margin-top: -1px;
              }
              
              :hover {
                border: 1px solid var(--mainColor);
              }
              
              &.focused {
                border: 1px solid var(--mainColor);

                svg {
                  circle {
                    stroke: #FFFFFF;
                  }
  
                  path {
                    stroke: #FFFFFF;
                  }
                }
                
               .close-btn {
                  path {
                    stroke: none;
                  }
                }
              }
              
              .close-btn {
                margin-top: 3px;
                width: 12px;
                cursor: pointer;

                path {
                  stroke: none;
                  fill: #FFFFFF
                }
              }

              svg {
                circle {
                  stroke: #BDBDBD;
                }

                path {
                  stroke: #BDBDBD;
                }
              }

              input {
                color: #fff;
              }
            }
          }
  
          @media(min-width: 768px) {
            min-height: var(--headerTabletHeight);
  
            .DealerInfoFragment a {
              font-size: 16px;
              justify-content: flex-end;
              
              span {
                text-align: right;
              }
            }
          }
  
          @media(min-width: 1200px) {
            min-height: var(--headerDesktopHeight);
          }
        `
    },
    dealFlow: {
      root: ({ isAuthPage, isOutsideFlowPage, isYourDetailsPage }: HeaderDealFlowConfig) => `
          position: sticky;
          top: 0;
  
          & .List {
            display: none;
          }
  
          & .MuiStepper-root {
            max-width: 240px;
          }
  
          & .cf-header-drawer-close-bar {
            border-bottom: 1px solid rgb(232, 235, 232);
          }
  
          & .cf-header-drawer-content {
            padding: 16px 24px 0px;
          }
  
          ${
        isYourDetailsPage === true
          ? (`
                & .Login, .DrawerMenu, .DealerInfoFragment {
                  display: none;
                }
              `)
          : ''
      }
  
          ${isOutsideFlowPage === true
        ? (`
                & .Login {
                  display: block;
                }
  
                & .DrawerMenu {
                  display: none;
                }
              `)
        : ''
      }
  
          /* order is important as auth pages are also outside flow */
          ${isAuthPage === true
        ? (`
                & .Login {
                  display: none;
                }
  
                & .DrawerMenu {
                  display: none;
                }
              `)
        : ''
      }
        `
    }
  }
}
