import ctaImg1 from 'website/assets/loving-african-american-kids-kissing-their-father-home-ver2.png'
import ctaImg2 from 'website/assets/woman-enjoying-her-financially-independence-while-buying-car-ver2.png'

const assets: Record<string, string> = {
  ctaImg1,
  ctaImg2,
  block1Text1: 'Different Technological Approach',
  block1Text2: 'DriveSimple is a leading dealership and solutions provider for the automotive industry. The future of car dealerships is likely automated sales processes with a focus on vehicle reconditioning and service.',
  block1Text3: 'We’re redefining car buying to reflect the experience people desire: flexible, convenient and shaped to their life. DriveSimple as one simple mission: to simplify the car selling process. It’s our passion, and it’s why we work so hard for our customers.',
  block2Text1: 'Full Transparency',
  block2Text2: "Shop our wide range of affordable vehicles from the comfort of your home. Our pricing isn't just low, it's smarter. So, you'll save time and money. We focus on building the most transparent, trustworthy experience for our users.",
  block2Text3: 'Cars are our business. We know how important they can become in a person’s life. We know that making the decision to sell a car can be a tough one. That’s why we guarantee our customers that we’ll provide them with a competitive offer on their vehicle.'
}

export default assets
